
import React, { useState, useEffect } from 'react';
import './App.scss';
import ReactDOM from 'react-dom'
import Snowfall from 'react-snowfall'
import ReactPlayer from 'react-player'

function App() {

  function volunteer() {
    alert('Coming Soon!')
  }

  const list = ["QhRGzvIlLPg","LoggufKZ4cU","TFlGRfY8Wyw","jYiIvSRlk9w","-CuX8vGjFhE","WFUWR2utVZM","34kTi5a3tC0","g9dmJ-C0akg","EHrMPYHFN-E","364UXLr4Kvg","AAyFJiDICXU","xHdXRNCXB3c","OYo-oGMo2jY"].map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value)

  console.log(list)


  return (
    <div className="App">
<Snowfall />

      {/* <header className="App-header">

<div class="rocket">
  <ul class="rocket__windows">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
  <ul class="rocket__front-fin">
    <li></li>
    <li></li>
  </ul>
  <div class="rocket__body"></div>
  <div class="rocket__nose"></div>
  <div class="rocket__jets-cover"></div>
  <ul class="flames">
    <li class="flames--red"></li>
    <li class="flames--orange"></li>
    <li class="flames--yellow"></li>
    <li class="flames--white"></li>
  </ul>
  <ul class="rocket__fins">
    <li>     </li>
    <li>     </li>
    <li>     </li>
    <li>     </li>
  </ul>
  <ul class="rocket__jets">
    <li> </li>
    <li> </li>
    <li> </li>
  </ul>
</div>

      </header> */}

{/* {list.map((video) =>
    <div key={video}>
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={'https://www.youtube.com/watch?v='+video}
          width='100%'
          height='100%'
        />
      </div>
    </div>
  )} */}

      
      

    </div>
  );
}

export default App;
